import React from 'react';
import PropTypes from 'prop-types';

import AddOn from './AddOn';
import AddOnOutline from './AddOnOutline';
import AlertCircle from './AlertCircle';
import AlertTriangle from './AlertTriangle';
import ArrowDown from './ArrowDown';
import ArrowLeft from './ArrowLeft';
import ArrowRight from './ArrowRight';
import ArrowUp from './ArrowUp';
import Bills from './Bills';
import Bell from './Bell';
import Bonus from './Bonus';
import BoxTruck from './BoxTruck';
import Bundled from './Bundled';
import Bundling from './Bundling';
import Calendar from './Calendar';
import CardboardBox from './CardboardBox';
import Check from './Check';
import ChevronDown from './ChevronDown';
import ChevronLeft from './ChevronLeft';
import ChevronRight from './ChevronRight';
import ChevronUp from './ChevronUp';
import Clock from './Clock';
import ClockFilled from './ClockFilled';
import CloseThin from './CloseThin';
import Download from './Download';
import ExclamationFilled from './ExclamationFilled';
import Flag from './Flag';
import Gear from './Gear';
import Globe from './Globe';
import Gps from './Gps';
import Help from './Help';
import Info from './Info';
import MapPin from './MapPin';
import More from './More';
import NewWindow from './NewWindow';
import Paperclip from './Paperclip';
import PaperMap from './PaperMap';
import PedestrianCircle from './PedestrianCircle';
import Plus from './Plus';
import PricingV2 from './PricingV2';
import Receipt from './Receipt';
import ReturnHome from './ReturnHome';
import Search from './Search';
import SmartOrderMatching from './SmartOrderMatching';
import Sparkle from './Sparkle';
import Star from './Star';
import Trash from './Trash';
import Tailored from './Tailored';

const Icon = ({ name, ...rest }) => {
  const icons = {
    addOn: <AddOn {...rest} />,
    addOnOutline: <AddOnOutline {...rest} />,
    alertCircle: <AlertCircle {...rest} />,
    alertTriangle: <AlertTriangle {...rest} />,
    arrowDown: <ArrowDown {...rest} />,
    arrowLeft: <ArrowLeft {...rest} />,
    arrowRight: <ArrowRight {...rest} />,
    arrowUp: <ArrowUp {...rest} />,
    bonus: <Bonus {...rest} />,
    bell: <Bell {...rest} />,
    bills: <Bills {...rest} />,
    boxTruck: <BoxTruck {...rest} />,
    bundled: <Bundled {...rest} />,
    bundling: <Bundling {...rest} />,
    calendar: <Calendar {...rest} />,
    cardboardBox: <CardboardBox {...rest} />,
    check: <Check {...rest} />,
    chevronDown: <ChevronDown {...rest} />,
    chevronLeft: <ChevronLeft {...rest} />,
    chevronRight: <ChevronRight {...rest} />,
    chevronUp: <ChevronUp {...rest} />,
    clock: <Clock {...rest} />,
    clockFilled: <ClockFilled {...rest} />,
    closeThin: <CloseThin {...rest} />,
    exclamationFilled: <ExclamationFilled {...rest} />,
    download: <Download {...rest} />,
    flag: <Flag {...rest} />,
    gear: <Gear {...rest} />,
    globe: <Globe {...rest} />,
    gps: <Gps {...rest} />,
    help: <Help {...rest} />,
    info: <Info {...rest} />,
    mapPin: <MapPin {...rest} />,
    more: <More {...rest} />,
    newWindow: <NewWindow {...rest} />,
    paperclip: <Paperclip {...rest} />,
    paperMap: <PaperMap {...rest} />,
    pedestrianCircle: <PedestrianCircle {...rest} />,
    plus: <Plus {...rest} />,
    pricingV2: <PricingV2 {...rest} />,
    receipt: <Receipt {...rest} />,
    returnHome: <ReturnHome {...rest} />,
    search: <Search {...rest} />,
    smartOrderMatching: <SmartOrderMatching {...rest} />,
    sparkle: <Sparkle {...rest} />,
    star: <Star {...rest} />,
    trash: <Trash {...rest} />,
    tailored: <Tailored {...rest} />
  };

  return icons[name] || null;
};

Icon.propTypes = {
  className: PropTypes.string,
  fill: PropTypes.string,
  name: PropTypes.string.isRequired,
  size: PropTypes.number
};

export default Icon;
